import React from "react"
import {Container} from 'react-bootstrap'
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import AdvertContact from "../components/advert"

const BlogPage = ({
    data: {
      allMarkdownRemark: { edges },
    },
  }) => {

  const Posts = edges
    .filter(edge => (!!edge.node.frontmatter.date)&&(edge.node.frontmatter.type === "blog")) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
  <>
    <Header />
    <SEO title="Blog" />
    <Container className="mt-4">
      <h1>Client Stories and Blog Posts</h1>
      <div>{Posts}</div>
      <AdvertContact />
    </Container>
    <Footer />
  </>
  )
} 


export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            type
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            blurb
          }
        }
      }
    }
  }
`